<template>
  <div class="surveyPage">
    <carouselBanner paddingTop="14.6%" :bannerType="2" />
    <!--    <commonPageBanner></commonPageBanner>-->
    <div class="content_area clearfix" :class="{ naVEN: LOCALE == 'en' }">
      <div class="surveyNav fl">
        <div
          class="tc surveyNavItem"
          :class="{ active: currentPath == item.link }"
          v-for="(item, index) in navLinks"
          :key="index"
        >
          <router-link :to="item.link">{{ item.label }}</router-link>
        </div>
      </div>
      <div class="surveyChildPage fr">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
// import commonPageBanner from "~hns/components/layouts/pageBanner";
export default {
  components: { carouselBanner },
  data() {
    return {
      currentPath: "",
      navLinks: [
        { label: this.$t("synopsisAssociation"), link: "/survey" },
        { label: this.$t("tissueChart"), link: "/survey/framework" },
        // { label: "领导成员", link: "/survey/leader" },
        { label: this.$t("unitDirector"), link: "/survey/director" },
        { label: this.$t("unitSupervisor"), link: "/survey/supervisor" },
        { label: this.$t("unitMember"), link: "/survey/member" },
        { label: this.$t("associationArticle"), link: "/survey/constitution" },
        { label: this.$t("bigEvents"), link: "/survey/memorabilia" },
      ],
    };
  },
  mounted() {
    this.currentPath = this.$route.path;
  },
  methods: {
    handleActive(path) {
      if (path.indexOf("survey") != -1) {
        this.currentPath = path;
      }
    },
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);
    },
  },
};
</script>

<style scoped lang="less">
.surveyPage {
  padding-bottom: 24px;
  .surveyNav {
    width: 110px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    padding: 5px 10px;
  }

  .surveyNavItem {
    width: 90px;
    height: 36px;
    line-height: 36px;
    margin: 10px auto;
    a {
      color: #666;
    }
    &.active,
    &:hover {
      background: rgba(51, 119, 255, 0.1);
      border-radius: 6px;
      a {
        color: #3377ff;
      }
    }
  }
  .surveyChildPage {
    width: 1066px;
  }
  .naVEN {
    .surveyNav {
      width: 190px;
      .surveyNavItem {
        width: 165px;
      }
    }
    .surveyChildPage {
      width: 1000px;
    }
  }
}
</style>